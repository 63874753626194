import { fetch, post } from '@/utils/axios'

export default {
  TransferRecords: params => {
    return post('/app/TransferRecords', params)
  },
  getRecords: params => {
    return fetch('/app/TransferRecords', params)
  },
  amounts: params => {
    return fetch('/app/Users/amounts', params)
  },
  exchangeRate: params => {
    return fetch('/Event/exchangeRate', params)
  },
  legalChange: data => {
    return post('/app/Users/legalChange', data)
  },
  orderList: params => {
    return fetch('/app/Order', params)
  },
  userInfo: () => {
    return fetch('/app/Users')
  },
  RechargeChannel: () => {
    return fetch('/app/RechargeChannel/channel')
  },
  charge: data => {
    return post('/app/TransferRecords/charge', data)
  },
  upper: data => {
    return post('/app/TransferRecords/upper', data)
  },
  lower: data => {
    return post('/app/TransferRecords/lower', data)
  },
  WalletAddress: data => {
    return fetch('/app/WalletAddress', data)
  },
  WalletAddressAdd: data => {
    return post('/app/WalletAddress/create', data)
  },
  WalletAddressUpdate: (id, data) => {
    return post(`/app/WalletAddress/update/${id}`, data)
  },
  upImage: params => {
    return post('/Captcha/file', params)
  }
}
